import React from 'react';

const NotEyesBorder = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="70"
    height="70"
    viewBox="0 0 70 70"
  >
    <defs>
      <clipPath>
        <rect width="46.553" height="36.198" fill="currentColor" />
      </clipPath>
    </defs>
    <g transform="translate(-610 -1697)">
      <g transform="translate(-201 1426)">
        <g transform="translate(811 271)" fill="none" stroke="currentColor" strokeWidth="1">
          <rect width="70" height="70" stroke="none" />
          <rect x="0.5" y="0.5" width="69" height="69" fill="none" />
        </g>
      </g>
      <g transform="translate(621.723 1713.151)">
        <rect
          width="1.819"
          height="43.79"
          transform="matrix(0.802, -0.598, 0.598, 0.802, 9.463, 1.087)"
          fill="currentColor"
        />
        <g>
          <g clipPath="url(#clip-path)">
            <path
              d="M23.448,24.958a25.91,25.91,0,0,1,15.118,5.293,35.359,35.359,0,0,1,7.4,7.183c.792,1.029.793,1.218-.079,2.168a44.109,44.109,0,0,1-12.876,9.981A21.323,21.323,0,0,1,22.3,52.047a21.783,21.783,0,0,1-7.9-2.056A42.472,42.472,0,0,1,1.878,40.9C1.346,40.363.837,39.8.332,39.239a.949.949,0,0,1-.024-1.421,43.411,43.411,0,0,1,14.147-10.8,20.825,20.825,0,0,1,8.993-2.058M44.362,38.549c-.148-.194-.2-.267-.257-.336a33.9,33.9,0,0,0-9.841-8.348,23.019,23.019,0,0,0-9.915-2.949,19.657,19.657,0,0,0-9.925,2.271A41.7,41.7,0,0,0,3.906,36.861c-.55.532-1.077,1.09-1.569,1.589.943.972,1.8,1.939,2.74,2.809a39.639,39.639,0,0,0,10.134,6.96,18.079,18.079,0,0,0,15.581.242,36.182,36.182,0,0,0,9-5.734c1.559-1.337,3.037-2.77,4.572-4.178"
              transform="translate(0 -20.416)"
              fill="currentColor"
            />
            <path
              d="M100.84,64.665a7.745,7.745,0,1,1-7.728-7.735,7.732,7.732,0,0,1,7.728,7.735m-1.931,0a5.814,5.814,0,1,0-5.8,5.815,5.828,5.828,0,0,0,5.8-5.815"
              transform="translate(-69.823 -46.573)"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default NotEyesBorder;
